"use client";

import { SiFacebook, SiInstagram, SiLinkedin, SiX, SiYoutube } from "@icons-pack/react-simple-icons";
import Link from "next/link";
import { ReactNode } from "react";
import { useIntl } from "react-intl";
import { DarkModeToggleButton } from "@/components/dark-mode-toggle-button/dark-mode-toggle-button";
import { Logo } from "@/components/logo/logo";

const ProductLayout = (properties: { children: ReactNode }) => {
  const { children } = properties;
  const intl = useIntl();

  return (
    <div className="flex h-full flex-col">
      <header className="flex h-16 shrink-0 border-b border-transparent">
        <nav className="mx-auto flex w-full max-w-7xl items-center justify-between px-6 lg:px-8">
          <div className="flex lg:flex-1">
            <Link
              className="relative -m-1.5 flex items-center rounded-md p-1.5 text-neutral-12"
              href="/"
              title={intl.formatMessage({
                defaultMessage: "Defense Futures Simulator",
                id: "KLcY/2",
              })}
            >
              <Logo className="h-8 w-auto text-primary-12" />
              <span className="ms-3 text-xl tracking-tighter text-primary-12">
                {intl.formatMessage({
                  defaultMessage: "DFS",
                  id: "vYwf3w",
                })}
              </span>
            </Link>
          </div>
          <div className="flex flex-1 items-center justify-end gap-x-6">
            <Link className="rounded-md text-sm font-semibold leading-6 text-primary-12" href="/sign-in">
              {intl.formatMessage({
                defaultMessage: "Sign in",
                id: "SQJto2",
              })}
            </Link>
            <Link
              className="rounded-md px-3 py-1.5 text-sm font-semibold text-primary-12 ring-1 ring-primary-7 hover:border-primary-8 hover:bg-primary-4 active:bg-primary-5"
              href="/sign-up"
            >
              {intl.formatMessage({
                defaultMessage: "Sign up",
                id: "8HJxXG",
              })}
            </Link>
          </div>
        </nav>
      </header>
      <div className="flex-1">{children}</div>
      <footer className="bg-canvas-dark-2 dark:bg-canvas-dark-1">
        <div className="mx-auto max-w-7xl px-6 pb-6 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8">
              <Link
                className="relative -ms-1 inline-block rounded-md p-1 focus-visible:outline-primary-dark-7"
                href="/"
              >
                <Logo className="h-8 w-auto text-primary-dark-11" />
              </Link>
              <p className="text-sm leading-6 text-neutral-dark-11">
                {intl.formatMessage({
                  defaultMessage:
                    "Analyzing defense budgets all around the world with artificial intelligence.",
                  id: "rExv0I",
                })}
              </p>
              <div className="-ms-0.5 flex space-x-6">
                <Link
                  className="rounded-md p-0.5 text-primary-dark-11 hover:text-primary-dark-12 focus-visible:outline-primary-dark-7"
                  href="https://www.facebook.com/aei"
                  rel="noopener"
                  target="_blank"
                >
                  <span className="sr-only">
                    {intl.formatMessage({
                      defaultMessage: "Facebook",
                      id: "EmpHyB",
                    })}
                  </span>
                  <SiFacebook aria-hidden className="size-5" />
                </Link>
                <Link
                  className="rounded-md p-0.5 text-primary-dark-11 hover:text-primary-dark-12 focus-visible:outline-primary-dark-7"
                  href="http://instagram.com/aei"
                  rel="noopener"
                  target="_blank"
                >
                  <span className="sr-only">
                    {intl.formatMessage({
                      defaultMessage: "Instagram",
                      id: "39PtLD",
                    })}
                  </span>
                  <SiInstagram aria-hidden className="size-5" />
                </Link>
                <Link
                  className="rounded-md p-0.5 text-primary-dark-11 hover:text-primary-dark-12 focus-visible:outline-primary-dark-7"
                  href="https://x.com/aei"
                  rel="noopener"
                  target="_blank"
                >
                  <span className="sr-only">
                    {intl.formatMessage({
                      defaultMessage: "X",
                      id: "MXPwVk",
                    })}
                  </span>
                  <SiX aria-hidden className="size-5" />
                </Link>
                <Link
                  className="rounded-md p-0.5 text-primary-dark-11 hover:text-primary-dark-12 focus-visible:outline-primary-dark-7"
                  href="https://www.linkedin.com/company/american-enterprise-institute"
                  rel="noopener"
                  target="_blank"
                >
                  <span className="sr-only">
                    {intl.formatMessage({
                      defaultMessage: "LinkedIn",
                      id: "Rb/hb9",
                    })}
                  </span>
                  <SiLinkedin aria-hidden className="size-5" />
                </Link>
                <Link
                  className="rounded-md p-0.5 text-primary-dark-11 hover:text-primary-dark-12 focus-visible:outline-primary-dark-7"
                  href="http://www.youtube.com/user/AEIVideos"
                  rel="noopener"
                  target="_blank"
                >
                  <span className="sr-only">
                    {intl.formatMessage({
                      defaultMessage: "YouTube",
                      id: "zuA3Bg",
                    })}
                  </span>
                  <SiYoutube aria-hidden className="size-5" />
                </Link>
              </div>
            </div>
            <div className="mt-16 grid grid-cols-1 gap-8 md:grid-cols-2 xl:col-span-2 xl:mt-0">
              <div className="grid grid-cols-2 md:gap-8 xl:col-start-3">
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-neutral-dark-12">
                    {intl.formatMessage({
                      defaultMessage: "Service",
                      id: "n7yYXG",
                    })}
                  </h3>
                  <ul className="mt-6 space-y-4">
                    <li>
                      <Link
                        className="rounded-md text-sm leading-6 text-neutral-dark-11 hover:text-neutral-dark-12 focus-visible:outline-primary-dark-7"
                        href="/"
                      >
                        {intl.formatMessage({
                          defaultMessage: "About",
                          id: "g5pX+a",
                        })}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="rounded-md text-sm leading-6 text-neutral-dark-11 hover:text-neutral-dark-12 focus-visible:outline-primary-dark-7"
                        href="/#partners"
                      >
                        {intl.formatMessage({
                          defaultMessage: "Partners",
                          id: "Uu3DS2",
                        })}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="rounded-md text-sm leading-6 text-neutral-dark-11 hover:text-neutral-dark-12 focus-visible:outline-primary-dark-7"
                        href="/#testimonials"
                      >
                        {intl.formatMessage({
                          defaultMessage: "Testimonials",
                          id: "X7+QFF",
                        })}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="rounded-md text-sm leading-6 text-neutral-dark-11 hover:text-neutral-dark-12 focus-visible:outline-primary-dark-7"
                        href="https://www.aei.org/about/contact"
                        rel="noopener"
                        target="_blank"
                      >
                        {intl.formatMessage({
                          defaultMessage: "Contact us",
                          id: "WnQ/AZ",
                        })}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="">
                  <h3 className="text-sm font-semibold leading-6 text-neutral-dark-12">
                    {intl.formatMessage({
                      defaultMessage: "Legal",
                      id: "7oFrM6",
                    })}
                  </h3>
                  <ul className="mt-6 space-y-4">
                    <li>
                      <Link
                        className="rounded-md text-sm leading-6 text-neutral-dark-11 hover:text-neutral-dark-12 focus-visible:outline-primary-dark-7"
                        href="/acceptable-use-policy"
                      >
                        {intl.formatMessage({
                          defaultMessage: "Acceptable use policy",
                          id: "eclgyX",
                        })}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="rounded-md text-sm leading-6 text-neutral-dark-11 hover:text-neutral-dark-12 focus-visible:outline-primary-dark-7"
                        href="/cookie-policy"
                      >
                        {intl.formatMessage({
                          defaultMessage: "Cookie policy",
                          id: "F4wEPb",
                        })}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="rounded-md text-sm leading-6 text-neutral-dark-11 hover:text-neutral-dark-12 focus-visible:outline-primary-dark-7"
                        href="/privacy-policy"
                      >
                        {intl.formatMessage({
                          defaultMessage: "Privacy policy",
                          id: "cPwv2c",
                        })}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="rounded-md text-sm leading-6 text-neutral-dark-11 hover:text-neutral-dark-12 focus-visible:outline-primary-dark-7"
                        href="/terms-of-service"
                      >
                        {intl.formatMessage({
                          defaultMessage: "Terms of service",
                          id: "FqgDIV",
                        })}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-16 flex items-center border-t border-neutral-dark-6 pt-6 sm:mt-20 lg:mt-24">
            <p className="text-xs leading-5 text-neutral-dark-9">
              <span className="block lg:inline">
                {intl.formatMessage({
                  defaultMessage: "American Enterprise Institute",
                  id: "30/gpi",
                })}
              </span>
              <span aria-hidden className="hidden px-1.5 lg:inline">
                {intl.formatMessage({
                  defaultMessage: "•",
                  id: "5APNfr",
                })}
              </span>
              <span className="block lg:inline">
                {intl.formatMessage({
                  defaultMessage: "Center for Strategic and International Studies",
                  id: "8imypl",
                })}
              </span>
              <span aria-hidden className="hidden px-1.5 lg:inline">
                {intl.formatMessage({
                  defaultMessage: "•",
                  id: "5APNfr",
                })}
              </span>
              <span className="block lg:inline">
                {intl.formatMessage(
                  {
                    defaultMessage: "© {date, date, ::yyyy} Spear AI, Inc. All rights reserved",
                    id: "B/9C1S",
                  },
                  {
                    date: new Date(),
                  },
                )}
              </span>
            </p>
            <div className="-me-2 ms-auto">
              <DarkModeToggleButton isDark />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ProductLayout;
